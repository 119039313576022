<template>
    <main class="models-page">

        <section-stats v-if="round['likes']"
                :dislikes="round['likes']['dislikes_count']"
                :likes="round['likes']['likes_count']"
                :views="0"
                :comments="round['comments']['count']"/>

        <section>
            <div class="container py-5">
                <div class="row">

                    <div class="md-down:col-12 lg:col-5 xl:col-3">
                        <mega-card class="card-border card-round">

                            <div class="card-media cursor-pointer card-video"
                                 ref="media"
                                 @click="video_toggle"
                                 v-if="video_link">

                                <mega-image ratio="9x16" :src="round['url_preview']" v-if="!round['url_preview']"/>
                                <img class="w-100" :src="round['url_preview']" v-if="round['url_preview']" alt="">

                                <div class="media-overlay overlay-90 overlay-dark media-video active z-100">
                                    <video ref="video" class="video-player fill-parent" :src="video_link" loop></video>
                                </div>

                                <div class="media-overlay overlay-50 overlay-dark z-200" v-if="!playVideo">
                                    <div class="text-center">
                                        <span class="sticker sticker-lg bg-white"><i class="i-play"></i></span>
                                    </div>
                                </div>
                            </div>

							<div class="card-media" v-else-if="round.youtube">
								<mega-youtube ratio="16x9" :src="round.youtube.id"/>
							</div>

                            <div class="card-media" v-else>
                                <mega-image ratio="9x16" :src="round['url_preview']" v-if="!round['url_preview']"/>
                                <img class="w-100" :src="round['url_preview']" v-if="round['url_preview']" alt="">
                            </div>


                            <div class="navbar py-3">
                                <div class="container-fluid">
                                    <div class="navbar-label w-100 text-dark">

                                        <mega-image class="rounded-full navbar-avatar mr-3" small ratio="1x1" style="overflow:hidden;" :src="from.avatar"/>

                                        <div class="content">
                                            <small class="strong">
                                                <i v-if="type === 'Arr'" class="i-globe text-muted text-arround align-middle mr-1"></i>
                                                <span>{{from.name}}</span>
                                            </small>
                                            <small class="text-muted">@{{from.username}}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <nav class="nav flex-column small">
                                <div class="nav-text text-muted">
                                    <i class="i-calendar"></i>
                                    <span>{{round['a_time'] | u2d("MMMM DD YYYY - LT")}}</span>
                                </div>
                            </nav>
                        </mega-card>

                        <mega-card :title="$t('round_model')" v-if="modification.idt_model">

<!--                            <router-link :to="'/'+type+'/'+modification.idt_model" class="navbar py-3 text-dark">-->
                            <router-link :to="{name: type, params: {modelId: modification.idt_model}}" class="navbar py-3 text-dark">
                                <div class="container-fluid">

                                    <div class="navbar-label">

                                        <mega-image class="rounded navbar-avatar mr-3" small :src="modification['url_icon']"/>

                                        <div class="content">
                                            <small class="strong">{{modification.name}}</small>
                                            <small class="text-muted">#{{modification.idt_model}}</small>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </mega-card>

                        <qr-code :uri="qrParams"/>

                        <mega-card>

                            <a class="btn text-primary rounded-0 d-block" target="_blank" :href="web_link">{{ $t('open_web') }}</a>
                            <mega-button class="btn-danger rounded-0 w-100 text-uppercase" @click="delete_round">{{ $t('del') }}</mega-button>
                        </mega-card>
                    </div>

                    <div class="col">

                        <mega-card :title="$t('round_msg')" v-if="round.message">
                            <div class="card-body">
                                {{round.message}}
                            </div>
                        </mega-card>

                        <no-ssr>
                            <map-card ratio="16x9"
                                      :place="round['place_address']"
                                      :address="round['place_name']"
                                      :lat="round.lat || 0"
                                      :lng="round.lng || 0"/>
                        </no-ssr>
                    </div>
                </div>
            </div>
        </section>

        <delete-confirm ref="delete-confirm"
                        :title="`${ $t('del_round') } #${round_id}`"
                        @confirm="deleteRound"
                        :text="$t('del_confirm')"
                        :round="round_id"/>
    </main>
</template>

<script>
    import MapCard from './components/map'
    import SectionStats from './components/stats'
    import DeleteConfirm from '../../components/delete-confirm'
    import QrCode from '../../components/qr-code'

    let url = process.env.APP_ENV === 'master' ? 'https://ar.arround.world/' : 'https://ardev.arround.world/';

    export default {
        name: 'RoundPage',
        props: ['round_id'],
        data() {
            return {
                loaded: false,
                playVideo: false,
                url,
                round: {},
                from: {},
                arp: {},
                modification: {}
            }
        },
        computed: {
          qrParams() {
              return `type=round&id=${this.round_id}`;
          },

            profileType() {
                let type = undefined;

                if(this.from.type === 'user') type = 'User';
                if(this.from.type === 'arp') type = 'Arr';

                return type;
            },

            type() {
                let res = undefined;

                if(this.round.idc_round_type === 4) res = 'Model';
                if(this.round.idc_round_type === 5) res = 'Gif';

                return res;
            },

            web_link() {
                return this.url+this.from.username+'/'+this.round['idt_round']
            },

            video_link() {
                return this.round.media ? this.round.media['url'] : (this.round.videos ? this.round.videos[0]['url'] : null);
            }
        },
        mounted() {
            this.$navbar.name = `${this.$t('round')} #${this.round_id}`;

            this.$api.v2.get('/user/round', {params: {idt_round: this.round_id}})
                .then(response => response.data.round)
                .then(round => {

                    this.from = round['from'];
                    delete round['from'];

                    if(!this.from.username)
                        this.from.username = this.from.type+'_'+this.from.id;

                    if(round['modification']) {
                        this.modification = round['modification'];
                        delete round['modification'];
                    }

                    if(round['modification_gif']) {
                        this.modification = round['modification_gif'];
                        delete round['modification'];
                    }

                    if(round['arp']) {
                        this.arp = round['arp'];
                        delete round['arp'];
                    }

                    this.round = round;

                    this.$render(this.round['url_preview'])
                        .then(() => { this.loaded = true })
                })
                .catch(() => { this.$router.replace('/404') })
        },
        methods: {

            delete_round() { this.$refs['delete-confirm'].open() },

            deleteRound(modal) {

                this.$api.v2.delete('/round', { params: { idt_round: this.round_id } })
                    .then(() => {
                        this.$refs['delete-confirm'].close()
                        this.$router.push({name: 'Arr_List'});
                        this.$alert.success(this.$t("round_deleted"))

                        modal.close();
                    })
                    .catch(() => this.$alert.danger(this.$t("err_server")))
            },

            video_toggle() {
                if(this.playVideo)
                    this.$refs['video'].pause();
                else
                    this.$refs['video'].play();

                this.playVideo = !this.playVideo;
            }
        },
        components: {
            QrCode,
            MapCard,
            SectionStats,
            DeleteConfirm
        }
    }
</script>
