var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "models-page" },
    [
      _vm.round["likes"]
        ? _c("section-stats", {
            attrs: {
              dislikes: _vm.round["likes"]["dislikes_count"],
              likes: _vm.round["likes"]["likes_count"],
              views: 0,
              comments: _vm.round["comments"]["count"]
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("section", [
        _c("div", { staticClass: "container py-5" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "md-down:col-12 lg:col-5 xl:col-3" },
              [
                _c("mega-card", { staticClass: "card-border card-round" }, [
                  _vm.video_link
                    ? _c(
                        "div",
                        {
                          ref: "media",
                          staticClass: "card-media cursor-pointer card-video",
                          on: { click: _vm.video_toggle }
                        },
                        [
                          !_vm.round["url_preview"]
                            ? _c("mega-image", {
                                attrs: {
                                  ratio: "9x16",
                                  src: _vm.round["url_preview"]
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.round["url_preview"]
                            ? _c("img", {
                                staticClass: "w-100",
                                attrs: {
                                  src: _vm.round["url_preview"],
                                  alt: ""
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "media-overlay overlay-90 overlay-dark media-video active z-100"
                            },
                            [
                              _c("video", {
                                ref: "video",
                                staticClass: "video-player fill-parent",
                                attrs: { src: _vm.video_link, loop: "" }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          !_vm.playVideo
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "media-overlay overlay-50 overlay-dark z-200"
                                },
                                [
                                  _c("div", { staticClass: "text-center" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "sticker sticker-lg bg-white"
                                      },
                                      [_c("i", { staticClass: "i-play" })]
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm.round.youtube
                    ? _c(
                        "div",
                        { staticClass: "card-media" },
                        [
                          _c("mega-youtube", {
                            attrs: { ratio: "16x9", src: _vm.round.youtube.id }
                          })
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "card-media" },
                        [
                          !_vm.round["url_preview"]
                            ? _c("mega-image", {
                                attrs: {
                                  ratio: "9x16",
                                  src: _vm.round["url_preview"]
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.round["url_preview"]
                            ? _c("img", {
                                staticClass: "w-100",
                                attrs: {
                                  src: _vm.round["url_preview"],
                                  alt: ""
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c("div", { staticClass: "navbar py-3" }, [
                    _c("div", { staticClass: "container-fluid" }, [
                      _c(
                        "div",
                        { staticClass: "navbar-label w-100 text-dark" },
                        [
                          _c("mega-image", {
                            staticClass: "rounded-full navbar-avatar mr-3",
                            staticStyle: { overflow: "hidden" },
                            attrs: {
                              small: "",
                              ratio: "1x1",
                              src: _vm.from.avatar
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _c("small", { staticClass: "strong" }, [
                              _vm.type === "Arr"
                                ? _c("i", {
                                    staticClass:
                                      "i-globe text-muted text-arround align-middle mr-1"
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(_vm.from.name))])
                            ]),
                            _vm._v(" "),
                            _c("small", { staticClass: "text-muted" }, [
                              _vm._v("@" + _vm._s(_vm.from.username))
                            ])
                          ])
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("nav", { staticClass: "nav flex-column small" }, [
                    _c("div", { staticClass: "nav-text text-muted" }, [
                      _c("i", { staticClass: "i-calendar" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("u2d")(
                              _vm.round["a_time"],
                              "MMMM DD YYYY - LT"
                            )
                          )
                        )
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _vm.modification.idt_model
                  ? _c(
                      "mega-card",
                      { attrs: { title: _vm.$t("round_model") } },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "navbar py-3 text-dark",
                            attrs: {
                              to: {
                                name: _vm.type,
                                params: { modelId: _vm.modification.idt_model }
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "container-fluid" }, [
                              _c(
                                "div",
                                { staticClass: "navbar-label" },
                                [
                                  _c("mega-image", {
                                    staticClass: "rounded navbar-avatar mr-3",
                                    attrs: {
                                      small: "",
                                      src: _vm.modification["url_icon"]
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "content" }, [
                                    _c("small", { staticClass: "strong" }, [
                                      _vm._v(_vm._s(_vm.modification.name))
                                    ]),
                                    _vm._v(" "),
                                    _c("small", { staticClass: "text-muted" }, [
                                      _vm._v(
                                        "#" + _vm._s(_vm.modification.idt_model)
                                      )
                                    ])
                                  ])
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("qr-code", { attrs: { uri: _vm.qrParams } }),
                _vm._v(" "),
                _c(
                  "mega-card",
                  [
                    _c(
                      "a",
                      {
                        staticClass: "btn text-primary rounded-0 d-block",
                        attrs: { target: "_blank", href: _vm.web_link }
                      },
                      [_vm._v(_vm._s(_vm.$t("open_web")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "mega-button",
                      {
                        staticClass:
                          "btn-danger rounded-0 w-100 text-uppercase",
                        on: { click: _vm.delete_round }
                      },
                      [_vm._v(_vm._s(_vm.$t("del")))]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col" },
              [
                _vm.round.message
                  ? _c("mega-card", { attrs: { title: _vm.$t("round_msg") } }, [
                      _c("div", { staticClass: "card-body" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.round.message) +
                            "\n                            "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "no-ssr",
                  [
                    _c("map-card", {
                      attrs: {
                        ratio: "16x9",
                        place: _vm.round["place_address"],
                        address: _vm.round["place_name"],
                        lat: _vm.round.lat || 0,
                        lng: _vm.round.lng || 0
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("delete-confirm", {
        ref: "delete-confirm",
        attrs: {
          title: _vm.$t("del_round") + " #" + _vm.round_id,
          text: _vm.$t("del_confirm"),
          round: _vm.round_id
        },
        on: { confirm: _vm.deleteRound }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }